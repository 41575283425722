import React from 'react';
import logo from '../logo.svg';
import '../css/App.css';

import Tabs from '../components/Tabs'
import HomeContent from '../components/HomeContent'
import BioContent from '../components/BioContent'
import ContactContent from '../components/ContactContent'

require('../css/bulma.min.css')
require('../css/Tabs.css')
require('../css/Content.css')

function App() {
  return (
   <div>
     <h1 className="App-header top-bar">npabba</h1>
     <Tabs>
      <HomeContent label="Home"></HomeContent>
      <BioContent label="Bio"></BioContent>
      <ContactContent label="Contact"></ContactContent>
    </Tabs>
   </div>
  );
}

export default App;
