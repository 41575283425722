import React, { Component } from 'react';

class HomeContent extends Component {
    render() {
        return(
            <div class="container">
            <div class="columns">
                <div class="column">
                    <img class="home-selfie" src="https://i.imgur.com/fWYy8oH.jpg"></img>
                </div>
                <div class="column">
                    <div class="content home-message">
                    <article class="message">
                        <div class="message-header">
                            <p>Hello World!</p>
                        </div>
                        <div class="message-body">
                            <p>
                            My name is Nihaal Pabba and I'm a software developer
                            based in Boston, MA. I specialize in backend development 
                            and have experience working in Java, C#, HBase, and SQL
                            in a professional environment. However, I am also 
                            comfortable working in Python and Scheme. 
                            </p>
                        </div>
                        </article>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default HomeContent;